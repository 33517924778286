'use client';

import styled from 'styled-components';
import Oneliner from 'src/core/components/oneliner/oneliner.svg';
import { Column } from '@app/core/components/layout';

export const StyledOneLinerColumn = styled(Column)`
  text-align: center;
`;

export const StyledOneliner = styled(Oneliner)`
  width: 100%;
  max-width: 320px;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.SM}px) {
    max-width: 638px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    max-width: 744px;
  }
`;
